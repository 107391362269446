import React from 'react';
import './Loading.css'; 

function Loading() {
  return (
    <div>
      <div className='loadding-ppl'>
        <img src="https://funnelstitan.com/serv/icons/logo.png" alt="FunnelsTitan" />
      </div>
    </div>
  );
}

export default Loading;