import React from 'react';
import './Footer.css'; 

function Footer() {

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-column">
          <div className="footer-content"> 
                <span className="text-12-s footext-w">
                Este sitio NO es parte de Facebook o Facebook Inc. Adem&aacute;s, este sitio no ha sido endorsado por Facebook..
                </span> 
              <div className='footer-pie-l'>
              </div> 
          </div>
          <div className="footer-pie">
            <div className='footer-pie-l'>
              <span className="text-14-s footext-w">&nbsp;all right &copy; - funnelstitan.com</span>
            </div>
            <div className='footer-pie-r'>
              <div className='box-vimark'>
                  <div className='vi-text'><span className="text-12-s footext-w"></span></div><div className='vi-link'><a className='link-back-w' href='https://viwhite.com' alt="Viwhite"><label className='vi-label vi-mark'>&nbsp;</label></a></div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

