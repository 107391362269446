import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Loading from './Loading'; // Importa tu componente de carga
import { AuthProvider } from './AuthContext';
//import Btnwapp from './partscomp/Btnwapp'; // Importa el componente Btnwapp
import Footer from './partscomp/Footer'; // Importa el componente Footer
import { Suspense } from 'react'; // Importa Suspense
import { Switch, Route } from 'react-router-dom'; // Importa Switch y Route
import reportWebVitals from './reportWebVitals';
import PagefinalTwo from './two-page/Pagefinal.js';

const history = createBrowserHistory();
const rootElement = document.getElementById('root');

const Contact = React.lazy(() => import('./two-page/Contacto.js'));
const Background = React.lazy(() => import('./partscomp/Background'));

createRoot(rootElement).render( 
  <React.StrictMode>  
    <AuthProvider> 
      <Router history={history}> 
        <Suspense fallback={<Loading />}>   
          <Switch>
            <Route exact path="/granted" component={PagefinalTwo} />
            <Route exact path="/fc/:usr" component={Contact} /> 
            <Route exact path="/fc/" component={Contact} />  
            <Route exact path="/:codigodeusuario" component={Background} />
            <Route exact path="/" component={Background} />  
          </Switch>
        </Suspense>                     
        <Footer />
      </Router> 
    </AuthProvider>
  </React.StrictMode>
);
reportWebVitals();
